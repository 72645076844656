import axios from 'axios'
import setData from '../redux/actions/setData'

export function loadData(store) {
    let bookings = [];
    let sellers = [];
    let products = [];

    axios.get('https://blooming-fortress-38880.herokuapp.com/bookings')
    .then(response => {
        bookings = response.data.data;
        axios.get('https://blooming-fortress-38880.herokuapp.com/sellers')
        .then(response => {
            sellers = response.data.data;            
            axios.get('https://blooming-fortress-38880.herokuapp.com/products')
            .then(response => {
                products = response.data.data;
                let data = { bookings, sellers, products };
                
                //console.log('data', data);
                store.dispatch(setData(data));
            });
        })
    });
}
