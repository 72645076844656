function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatDate(date) {
    let mm = zeroFormat(date.getMonth() + 1);
    let dd = zeroFormat(date.getDate());

    return date.getFullYear() + '-' + mm + '-' + dd;
}

function zeroFormat(n) {
    return (n < 10 ? '0' : '') + n;
}

export { formatNumber, currencyFormat, formatDate }