import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatNumber, currencyFormat, formatDate } from '../components/shared';
import Search from './search';

class BookingSummary extends Component {
    reportData = [];

    constructor(props) {
        super(props);

        this.state = {
            searchText: ''
        }
    }

    componentDidMount() {
    }

    render() {
        this.processData();

        return (
            <>
                <div className="row">
                    <h3 className="col-6">Bookings</h3>
                    <div className="col-6 align-right">
                        <Search searchText={this.state.searchText} onSearch={this.onSearch}/>
                    </div>
                </div>
                <hr/>
                {
                    this.reportData.map(seller =>
                        <div>
                            <h5 key={seller.id}>{seller.name}</h5>
                            <table className="base">
                                { seller.products && seller.products.length ?
                                    <tbody>
                                        <tr>
                                            <th>ID</th>
                                            <th>Product Name</th>
                                            <th className='text-right'>Quantity</th>
                                            <th className='text-right'>Rate</th>
                                            <th className='text-right'>Cost</th>
                                        </tr>
                                        
                                        { seller.products.map(data => 
                                            <tr key={data.id1} className={data.active ? 'bg-green' : ''}>
                                                <td key={data.id1}>{data.id}</td>
                                                <td>{data.productName}</td>
                                                <td className='text-right'>{data.quantity}</td>
                                                <td className='text-right'>{data.rate}</td>
                                                <td className='text-right'>{data.cost}</td>
                                            </tr>)
                                        }
                                    </tbody>
                                    : <tbody><tr><th>No active bookings.</th></tr></tbody>
                                }
                            </table>
                            <br/>
                        </div>
                    )
                }
            </>
        );
    }

    processData() {
        const { bookings, sellers, products } = this.props ? this.props.data : {};

        let data = {};
        let rows = [];
        let date = formatDate(new Date());

        if(bookings) {
            bookings.forEach(booking => {
                if(booking.endDate.substring(0,10) >= date) {

                    let product = products.filter(p => p.id === booking.productId)[0];
                    
                    if(this.props.mode === 0 || this.state.searchText.length <= 2 ||
                        (product.name.toUpperCase().indexOf(this.state.searchText.toUpperCase()) > -1)
                    ) {
                        let seller = sellers.filter(s => s.id === product.sellerId)[0];
                        data = {
                            id1: booking.id,
                            id: booking.id.substring(0, 5),
                            productName: product ? product.name : '',
                            quantity: formatNumber(booking.quantity),
                            rate: currencyFormat(product.rate),
                            cost: currencyFormat(booking.quantity * product.rate),
                            active: (booking.startDate.substring(0, 10) <= date),
                            startDate: booking.startDate,
                            endDate: booking.endDate
                        };
                        if(!rows[seller.id]) rows[seller.id] = { name: seller.name, products: [] };
                        rows[seller.id].products.push(data);
                    }
                }
            });

            let reportData = [];
            for(var r in rows) {
                rows[r].products = rows[r].products.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
                reportData.push({id: r, name: rows[r].name, products: rows[r].products});
            }

            let sellers2 = sellers.filter(s => reportData.filter(r => r.id === s.id).length === 0);
            reportData = [...reportData, ...sellers2];
            
            this.reportData = reportData;
        }
    }

    onSearch = searchText => {
        this.setState({ searchText: searchText });
    }
}

function mapStateToProps(state) {
    return {
        data: state ? state : {}
    };
}

export default connect(mapStateToProps)(BookingSummary);