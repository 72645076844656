import React, { Component } from 'react';
import './App.css';
import BookingSummary from './reports/bookingsummary';
import { configureStore } from './redux/store';

class App extends Component {
  mode = 0;

  store = configureStore();

  constructor(props) {
    super(props);

    this.state = {
      mode: 1
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className='App'>
        <BookingSummary />
      </div>
    );
  }

  setMode = mode => {
    this.setState({ mode });
  }
}

export default App;