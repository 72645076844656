import React, { Component } from 'react';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: this.props.searchText };
    }

    textChanged = event => {
        this.setState({ searchText: event.target.value });
        this.props.onSearch(event.target.value);
    }

    render() {
        return (
            <div className="row">
                <div className="col-6"><label className="float-right">Search</label></div>
                <input type="text" value={this.state.searchText} onChange={this.textChanged} className="col-6 search" placeholder="Search for booking by product name"/>
            </div>
        )
    }
}

export default Search;