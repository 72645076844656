import { createStore } from 'redux';
import setDataReducer from '../reducers/setDataReducer';
import { loadData } from '../../api/loadData';

export const configureStore = () => {
  const store = createStore(setDataReducer);
  loadData(store);

  return store;
}

export default configureStore;